































































import Vue from 'vue';
import { mapState } from 'vuex';
import RepositoryFactory from '@/services/RepositoryFactory';
import IShipmentProviderConfig, {
  IProvider,
} from '@/services/Repositories/shipmentProviderConfigs/types';

const Factory = new RepositoryFactory();

export default Vue.extend({
  name: 'ShippingProvider',
  components: {
    BasePopup: () => import('@/components/ui/BasePopup.vue'),
  },
  data() {
    return {
      providers: [] as Array<IProvider>,
      popup: false,
      providerToDeleteId: '',
    };
  },
  computed: {
    ...mapState('globals', ['loader']),
    shipmentRepository(): IShipmentProviderConfig {
      return Factory.get('shipmentConfig') as IShipmentProviderConfig;
    },
  },
  methods: {
    async getProviders(): Promise<void> {
      const {
        data: { 'hydra:member': providers },
      } = await this.shipmentRepository.get();
      this.providers = providers;
    },
    editProvider(id: string): void {
      this.$router.push({ name: 'Provider', params: { providerId: id } });
    },
    async deleteProvider(id: string): Promise<void> {
      this.popup = false;
      await this.shipmentRepository.delete(id);
      this.getProviders();
    },
    handleDeleteProvider(id: string): void {
      this.providerToDeleteId = id;
      this.popup = true;
    },
  },
  created() {
    const { icons, actions } = this.$headerService.getIconsAndActions();
    this.$headerService.config({
      title: 'header.shippingProviders',
      icons: { icon: icons.back, action: actions.goBack },
      page: { name: 'configurationIndex' },
    });
    this.getProviders();
  },
});
